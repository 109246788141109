import { ReactElement, createContext, useContext, useMemo } from 'react'
import { ITableInfinite } from './InfiniteTable.interfaces'

const InfiniteTableContext = createContext<ITableInfinite<any>>(undefined)

export const InfiniteTableProvider = <T,>({
  children,
  input,
}: {
  children: ReactElement
  input: ITableInfinite<T>
}) => {
  const displayedFields = useMemo(() => {
    return input?.displayedFields || input?.definitionFields.map((field) => field.indexName)
  }, [input?.displayedFields])

  return <InfiniteTableContext.Provider value={{ ...input, displayedFields }}>{children}</InfiniteTableContext.Provider>
}

export const useInfiniteTableContext = <T,>(): ITableInfinite<T> => useContext(InfiniteTableContext)
