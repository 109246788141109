import { ClientClass, Quotation, QuotationClass, Utils } from '@Arti-zen/package-backoffice'
import { PictureAsPdf } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import TableInfinite from 'components/table/infinite/InfiniteTable'
import { ITableInfinite } from 'components/table/infinite/InfiniteTable.interfaces'
import { useAppSelector } from 'hooks/useStore'
import { ReactElement, useEffect, useState } from 'react'
import { set } from 'react-hook-form'
import { StatusAlertService } from 'react-status-alert'
import { ApiArtizenBO } from 'utils/artizenConnector'

export default function ArtisanQuotationListHidden(): ReactElement {
  const { artisan } = useAppSelector((state) => state.artisan)

  const [quotList, setQuotList] = useState<QuotationClass[]>([])
  const [quotIdSelected, setQuotIdSelected] = useState<QuotationClass['id'] | null>(null)

  useEffect(() => {
    let isMount = true

    ApiArtizenBO.quotations.getHiddenList(artisan.id).then((quotList) => {
      isMount && setQuotList(quotList.map((item) => new QuotationClass(item)))
    })

    return () => {
      isMount = false
    }
  }, [artisan.id])

  const onUnarchive = (quotId: Quotation['id']) => {
    ApiArtizenBO.quotations
      .updateUnhide(quotId)
      .then(() => {
        setQuotList(quotList.filter((item) => item.id !== quotId))
        StatusAlertService.showSuccess('Devis affiché')
      })
      .catch((error) => StatusAlertService.showError(error?.message))
      .finally(() => setQuotIdSelected(null))
  }

  return (
    <>
      <TableInfinite
        data={quotList}
        definitionFields={definitionFields({ setQuotIdSelected })}
        nextPage={function (): void {
          throw new Error('Function not implemented.')
        }}
        isQueriedAllCompleted={true}
      />
      <Dialog
        open={Boolean(quotIdSelected)}
        onClose={() => setQuotIdSelected(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Afficher le devis</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirmer le désarchivage du devis n°{quotIdSelected} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="info" onClick={() => onUnarchive(quotIdSelected)}>
            OUI
          </Button>
          <Button variant="contained" color="error" onClick={() => setQuotIdSelected(null)} autoFocus>
            NON
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const definitionFields = ({
  setQuotIdSelected,
}: {
  setQuotIdSelected: (quotId: QuotationClass['id'] | null) => void
}): ITableInfinite<QuotationClass>['definitionFields'] => {
  return [
    {
      indexName: 'id',
      label: 'id',
      onClick: ({ id }) => {
        setQuotIdSelected(id)
      },
    },
    {
      indexName: 'ref',
      label: 'numéro',
      onClick: ({ id }) => {
        setQuotIdSelected(id)
      },
    },
    {
      indexName: 'title',
      label: 'titre',
      sortField: 'title',
      onClick: ({ id }) => {
        setQuotIdSelected(id)
      },
    },
    {
      indexName: 'client',
      label: 'client intervention',
      isSortable: true,
      format: ({ client }) => (client ? new ClientClass(client).displayFullName : ''),
      onClick: ({ id }) => {
        setQuotIdSelected(id)
      },
    },
    {
      indexName: 'billingClient',
      label: 'client facturation',
      isSortable: true,
      format: ({ billingClient }) => (billingClient ? new ClientClass(billingClient).displayFullName : ''),
      onClick: ({ id }) => {
        setQuotIdSelected(id)
      },
    },
    {
      indexName: 'creationDate',
      label: 'date',
      format: ({ creationDate }) => Utils.time.classicTimeDisplay(creationDate),
      isSortable: true,
      sortField: 'creationDate',
      onClick: ({ id }) => {
        setQuotIdSelected(id)
      },
    },
    {
      indexName: 'totalTTCDisplay',
      label: 'total ttc',
      format: ({ totalTTC_cts }) => Utils.displayFormatter.printAmount(totalTTC_cts / 100) + ' €',
      styles: { textAlign: 'right' },
      isSortable: true,
      sortField: 'totalTTC_cts',
      onClick: ({ id }) => {
        setQuotIdSelected(id)
      },
    },
    {
      indexName: 'status',
      label: 'statut',
      styles: { overflow: 'visible' },
      onClick: ({ id }) => {
        setQuotIdSelected(id)
      },
    },
    {
      indexName: 'pdfUrl',
      label: 'pdf',
      format: ({ pdfUrl }) => {
        if (!pdfUrl) return '-'
        return (
          <a href={pdfUrl} target="_blank" rel="noreferrer">
            <PictureAsPdf />
          </a>
        )
      },
    },
  ]
}
