import { ClientClass, Intervention, InterventionClass, InvoiceClass, Utils } from '@Arti-zen/package-backoffice'
import { PictureAsPdf } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal } from '@mui/material'
import TableInfinite from 'components/table/infinite/InfiniteTable'
import { ITableInfinite } from 'components/table/infinite/InfiniteTable.interfaces'
import { useAppSelector } from 'hooks/useStore'
import { ReactElement, useEffect, useState } from 'react'
import { StatusAlertService } from 'react-status-alert'
import { ApiArtizenBO } from 'utils/artizenConnector'

export default function ArtisanInvoiceListHidden(): ReactElement {
  const { artisan } = useAppSelector((state) => state.artisan)

  const [invList, setInvList] = useState<InvoiceClass[]>([])
  const [invIdSelected, setInvIdSelected] = useState<InvoiceClass['id'] | null>(null)

  useEffect(() => {
    let isMount = true

    ApiArtizenBO.invoices.getHiddenList(artisan.id).then((docList) => {
      isMount && setInvList(docList.map((item) => new InvoiceClass(item)))
    })

    return () => {
      isMount = false
    }
  }, [artisan.id])

  const onUnarchive = (invId: InvoiceClass['id']) => {
    ApiArtizenBO.invoices
      .updateUnhide(invId)
      .then(() => {
        setInvList(invList.filter((item) => item.id !== invId))
        StatusAlertService.showSuccess('Facture affichée')
      })
      .catch((error) => StatusAlertService.showError(error?.message))
      .finally(() => setInvIdSelected(null))
  }

  return (
    <>
      <TableInfinite
        data={invList}
        definitionFields={definitionFields({ setInvIdSelected })}
        nextPage={function (): void {
          throw new Error('Function not implemented.')
        }}
        isQueriedAllCompleted={true}
      />
      <Dialog
        open={Boolean(invIdSelected)}
        onClose={() => setInvIdSelected(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Afficher la facture</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirmer le désarchivage de la facture n°{invIdSelected} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="info" onClick={() => onUnarchive(invIdSelected)}>
            OUI
          </Button>
          <Button variant="contained" color="error" onClick={() => setInvIdSelected(null)} autoFocus>
            NON
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const definitionFields = ({
  setInvIdSelected,
}: {
  setInvIdSelected: (invId: InvoiceClass['id'] | null) => void
}): ITableInfinite<InvoiceClass>['definitionFields'] => {
  return [
    {
      indexName: 'id',
      label: 'id',
      onClick: ({ id }) => {
        setInvIdSelected(id)
      },
    },
    {
      indexName: 'ref',
      label: 'numéro',
      onClick: ({ id }) => {
        setInvIdSelected(id)
      },
    },
    {
      indexName: 'title',
      label: 'titre',
      sortField: 'title',
      onClick: ({ id }) => {
        setInvIdSelected(id)
      },
    },
    {
      indexName: 'client',
      label: 'client intervention',
      isSortable: true,
      format: ({ client }) => (client ? new ClientClass(client).displayFullName : ''),
      onClick: ({ id }) => {
        setInvIdSelected(id)
      },
    },
    {
      indexName: 'billingClient',
      label: 'client facturation',
      isSortable: true,
      format: ({ billingClient }) => (billingClient ? new ClientClass(billingClient).displayFullName : ''),
      onClick: ({ id }) => {
        setInvIdSelected(id)
      },
    },
    {
      indexName: 'creationDate',
      label: 'date',
      format: ({ creationDate }) => Utils.time.classicTimeDisplay(creationDate),
      isSortable: true,
      sortField: 'creationDate',
      onClick: ({ id }) => {
        setInvIdSelected(id)
      },
    },
    {
      indexName: 'totalTTCDisplay',
      label: 'total ttc',
      format: ({ totalTTC_cts }) => Utils.displayFormatter.printAmount(totalTTC_cts / 100) + ' €',
      styles: { textAlign: 'right' },
      isSortable: true,
      sortField: 'totalTTC_cts',
      onClick: ({ id }) => {
        setInvIdSelected(id)
      },
    },
    {
      indexName: 'status',
      label: 'statut',
      styles: { overflow: 'visible' },
      onClick: ({ id }) => {
        setInvIdSelected(id)
      },
    },
    {
      indexName: 'pdfUrl',
      label: 'pdf',
      format: ({ pdfUrl }) => {
        if (!pdfUrl) return '-'
        return (
          <a href={pdfUrl} target="_blank" rel="noreferrer">
            <PictureAsPdf />
          </a>
        )
      },
    },
  ]
}
