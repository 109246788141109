import { ClientClass, Intervention, InterventionClass, Utils } from '@Arti-zen/package-backoffice'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal } from '@mui/material'
import TableInfinite from 'components/table/infinite/InfiniteTable'
import { ITableInfinite } from 'components/table/infinite/InfiniteTable.interfaces'
import { useAppSelector } from 'hooks/useStore'
import { ReactElement, useEffect, useState } from 'react'
import { StatusAlertService } from 'react-status-alert'
import { Theme } from 'theme/theme'
import { ApiArtizenBO } from 'utils/artizenConnector'

export default function ArtisanInterventionListHidden(): ReactElement {
  const { artisan } = useAppSelector((state) => state.artisan)

  const [interList, setInterList] = useState<InterventionClass[]>([])
  const [interIdSelected, setInterIdSelected] = useState<InterventionClass['id'] | null>(null)

  useEffect(() => {
    let isMount = true

    ApiArtizenBO.interventions.getHiddenList(artisan.id).then((interList) => {
      isMount && setInterList(interList.map((item) => new InterventionClass(item)))
    })

    return () => {
      isMount = false
    }
  }, [artisan.id])

  const onUnarchive = (interId: InterventionClass['id']) => {
    ApiArtizenBO.interventions
      .updateUnhide(interId)
      .then(() => {
        setInterList(interList.filter((item) => item.id !== interId))
        StatusAlertService.showSuccess('Intervention affichée')
      })
      .catch((error) => StatusAlertService.showError(error?.message))
      .finally(() => setInterIdSelected(null))
  }

  return (
    <>
      <TableInfinite
        data={interList}
        definitionFields={definitionFields({ setInterIdSelected })}
        nextPage={function (): void {
          throw new Error('Function not implemented.')
        }}
        isQueriedAllCompleted={true}
      />
      <Dialog
        open={Boolean(interIdSelected)}
        onClose={() => setInterIdSelected(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Afficher l'intervention</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirmer le désarchivage de l'intervention n°{interIdSelected} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="info"
            // style={{ background: Theme.colors.greenArtizen, color: 'white' }}
            onClick={() => onUnarchive(interIdSelected)}
          >
            OUI
          </Button>
          <Button variant="contained" color="error" onClick={() => setInterIdSelected(null)} autoFocus>
            NON
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const definitionFields = ({
  setInterIdSelected,
}: {
  setInterIdSelected: (inter: InterventionClass['id'] | null) => void
}): ITableInfinite<InterventionClass>['definitionFields'] => {
  return [
    {
      indexName: 'id',
      label: 'id',
      sortField: 'id',
      onClick: ({ id }) => {
        setInterIdSelected(id)
      },
    },
    {
      indexName: 'title',
      label: 'titre',
      sortField: 'title',
      onClick: ({ id }) => {
        setInterIdSelected(id)
      },
    },
    {
      indexName: 'client',
      label: 'client intervention',
      format: ({ client }) => (client ? new ClientClass(client).displayFullName : ''),
      onClick: ({ id }) => {
        setInterIdSelected(id)
      },
    },
    {
      indexName: 'billingClient',
      label: 'client facturation',
      format: ({ billingClient }) => (billingClient ? new ClientClass(billingClient).displayFullName : ''),
      onClick: ({ id }) => {
        setInterIdSelected(id)
      },
    },
    {
      indexName: 'interventionStartDate',
      label: 'date de début',
      format: ({ interventionStartDate }) => Utils.time.classicTimeDisplay(interventionStartDate),
      onClick: ({ id }) => {
        setInterIdSelected(id)
      },
    },
    {
      indexName: 'interventionEndDate',
      label: 'date de fin',
      format: ({ interventionEndDate }) =>
        interventionEndDate ? Utils.time.classicTimeDisplay(interventionEndDate) : '',
      onClick: ({ id }) => {
        setInterIdSelected(id)
      },
    },
    {
      indexName: 'isAllDay',
      label: 'Toute la journee',
      onClick: ({ id }) => {
        setInterIdSelected(id)
      },
    },
    {
      indexName: 'status',
      label: 'statut',
      // format: ({ status, id }) => <QuotationListTableStatus status={status} id={id} />,
      styles: { overflow: 'visible' },
      onClick: ({ id }) => {
        setInterIdSelected(id)
      },
    },
    {
      indexName: 'hidden',
      label: 'supprimée',
      onClick: ({ id }) => {
        setInterIdSelected(id)
      },
    },
  ]
}
